import React, { useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useForm } from "react-hook-form";
import axios from "axios";

import { Button, StyledForm, FormField } from "../style";
import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Quote } from "../components/Quote";

const captchaKey = process.env.HCAPTCHA_PUBLIC_KEY;

export default function ContactPage() {
  const { register, handleSubmit } = useForm();
  const hcaptchaRef = useRef(null);
  const [status, setStatus] = useState("INIT"); // [INIT, SUBMITTING, DONE, ERROR]

  const onSubmit = async (formData, hcaptchaToken = "dev") => {
    setStatus("SUBMITTING");
    axios
      .post(process.env.CONTACT_FORM_URL, {
        formData,
        hcaptcha: hcaptchaToken,
      })
      .then(() => {
        setStatus("DONE");
      })
      .catch(err => {
        setStatus("ERROR");
        if (captchaKey) {
          hcaptchaRef.current.resetCaptcha();
        }
      });
  };

  const executeCaptcha = e => {
    e.preventDefault();
    if (captchaKey) {
      hcaptchaRef.current.execute();
    } else {
      // handleSubmit(onSubmit);
      // TODO: How to sumbit with no captcha!?
    }
  };

  return (
    <Layout>
      <Page
        pageTitle="Contact"
        pageDescription="Whether you have a question about Takiwā Ecosystem, pricing, need a demo, or anything else, we are ready to answer all your questions."
        backgroundUrl={
          "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_laptop.jpg"
        }
        backgroundPosition="150% 150%"
      >
        <Title>Contact Us</Title>
        <Quote>
          Whether you have a question about Takiwā Ecosystem, pricing, need a
          demo, or anything else, we are ready to answer all your questions.
        </Quote>
        {status === "DONE" ? (
          <Quote>
            Thank you for your message! <br />
            <br />
            Someone from the Takiwā Team will be in touch with you shortly.
          </Quote>
        ) : (
          <StyledForm name="contact" onSubmit={executeCaptcha}>
            <FormField>
              <label htmlFor="firstName">First Name</label>
              <input
                ref={register}
                id="firstName"
                name="firstName"
                type="text"
                required
              />
            </FormField>
            <FormField>
              <label htmlFor="lastName">Last Name</label>
              <input ref={register} id="lastName" name="lastName" type="text" />
            </FormField>
            <FormField>
              <label htmlFor="fromEmail">Email</label>
              <input
                ref={register}
                id="fromEmail"
                name="fromEmail"
                type="text"
                required
              />
            </FormField>
            <FormField>
              <label htmlFor="phone">Phone</label>
              <input ref={register} id="phone" name="phone" type="text" />
            </FormField>

            <FormField wide>
              <label htmlFor="message">Message</label>
              <textarea
                ref={register}
                cols="40"
                rows="5"
                id="message"
                name="message"
                required
              />
            </FormField>

            {captchaKey && (
              <HCaptcha
                ref={hcaptchaRef}
                sitekey={captchaKey}
                size="invisible"
                onVerify={handleSubmit(onSubmit)}
              />
            )}

            {status === "ERROR" && (
              <FormField wide>
                <Quote>Something went wrong. Please try again.</Quote>
              </FormField>
            )}

            <FormField wide>
              <Button
                primary
                type="submit"
                value="Submit"
                disabled={status === "SUBMITTING"}
              >
                Submit
              </Button>
            </FormField>
          </StyledForm>
        )}
      </Page>
    </Layout>
  );
}
